/* requires:
polyfill.js
*/

// form validation
const leadForm = {
  init: function (formSetting) {
    const { theForm } = formSetting;
    if (!theForm) {
      return false;
    }

    function getParameterByName(name, url) {
      if (!url) url = window.location.href;
      name = name.replace(/[\[\]]/g, '\\$&');
      var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }
    if (getParameterByName('sent') == 1) {
      theForm.insertAdjacentHTML(
        'beforebegin',
        "<p style='width: 100%; border: 1px solid #39b54a; border-radius: 4px; color: #39b54a; color: #39b54a; font-size: 16px; font-size: 16px; padding: 5px; text-align: center;'>Thank you! We have received your inquiry.</p>"
      );
    }

    this.validateOnBlur(theForm);

    // listening for click on the submit button
    const submitBtn = theForm.querySelector('button[type=submit]') || theForm.querySelector('input[type=submit]');
    submitBtn.addEventListener(
      'click',
      function (e) {
        e.preventDefault();
        const isFormValid = leadForm.isFormValid(theForm);
        if (!isFormValid) {
          theForm.querySelector('.validation-msg').classList.remove('d-none');
          return false;
        } else {
          leadForm.submit(formSetting);
        }
      },
      true
    );
  },
  validateOnBlur: function (theForm) {
    if (!theForm) {
      return false;
    }
    // realtime form fields validation using blur event
    const fields = theForm.querySelectorAll('.required');
    for (let field of fields) {
      const type = field.type;
      if (type === 'email') {
        field.addEventListener(
          'blur',
          function (e) {
            const x = field.value;
            const re =
              /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!re.test(x)) {
              field.classList.add('is-invalid');
            } else {
              field.classList.remove('is-invalid');
            }
          },
          true
        );
      } else if (type === 'tel') {
        field.addEventListener(
          'blur',
          function (e) {
            const x = field.value;
            const re = /[(]?\d{3}[)]?\s?-?\s?\d{3}\s?-?\s?\d{4}/;
            if (!re.test(x)) {
              field.classList.add('is-invalid');
            } else {
              field.classList.remove('is-invalid');
            }
          },
          true
        );
      } else {
        field.addEventListener(
          'blur',
          function (e) {
            const x = field.value;
            if (!x || x.trim() == '') {
              field.classList.add('is-invalid');
            } else {
              field.classList.remove('is-invalid');
            }
          },
          true
        );
      }
    }
  },
  isFormValid: function (theForm) {
    if (!theForm) {
      return false;
    }
    // form fields validation during submission
    const fields = theForm.querySelectorAll('.required');
    let isFormValid = true;
    for (let field of fields) {
      const type = field.type;
      if (type === 'email') {
        const x = field.value;
        const re =
          /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(x)) {
          field.classList.add('is-invalid');
          isFormValid = false;
        } else {
          field.classList.remove('is-invalid');
        }
      } else if (type === 'tel') {
        const x = field.value;
        const re = /[(]?\d{3}[)]?\s?-?\s?\d{3}\s?-?\s?\d{4}/;
        if (!re.test(x)) {
          field.classList.add('is-invalid');
          isFormValid = false;
        } else {
          field.classList.remove('is-invalid');
        }
      } else {
        const x = field.value;
        if (!x || x.trim() == '') {
          field.classList.add('is-invalid');
          isFormValid = false;
        } else {
          field.classList.remove('is-invalid');
        }
      }
    }
    return isFormValid;
  },
  submit: function (formSetting) {
    const { theForm } = formSetting;

    const email = theForm.querySelector('input[name="Email"]').value;
    theForm.querySelector('input[name="_fromemail"]').value = email;
    theForm.querySelector('input[name="_return"]').value = window.location.href + '?sent=1';

    theForm.action = 'https://home.uceusa.com/mailer.aspx';

    // a simple theForm.submit() doesn't work because we have name=submit in one of the input which override the submit() function on the form
    const submitFormFunction = Object.getPrototypeOf(theForm).submit;
    submitFormFunction.call(theForm);

    // console.log(theForm)
  },
};

leadForm.init({
  theForm: document.querySelector('form.question'),
});
